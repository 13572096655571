.help-desk {
    position: relative;
}
.help-desk button {
    cursor: pointer;
}
.help-desk .help-desk__menu {
    position: fixed;
    z-index: 10000;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin: 0;
    padding: 10px 15px;
    box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.35);
    border: none;
    border-radius: 3px 0 0 3px;
    outline: none;
    background: linear-gradient(180deg, #135e7e, #103b5b 35%, #015b91);
    list-style: none;
    color: #fff;
    transition: all 0.4s cubic-bezier(0.55, 0, 0.1, 1);
}
.help-desk .help-desk__content {
    position: fixed;
    z-index: 9999;
    top: 0;
    bottom: 0;
    right: -100vw;
    width: 100vw;
    background: #f1f6f7;
    transition: all 0.4s cubic-bezier(0.55, 0, 0.1, 1);
}
@media (min-width: 450px) {
    .help-desk .help-desk__content {
        right: -450px;
        width: 450px;
    }
}
.help-desk .help-desk__content > div {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.help-desk .help-desk__content .help-desk__header {
    position: relative;
    padding: 0 25px;
    background: linear-gradient(180deg, #135e7e, #103b5b 35%, #015b91);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
    color: #fff;
}
.help-desk .help-desk__content .help-desk__header h1 {
    margin: 0;
    font-size: 16px;
    line-height: 48px;
}
.help-desk .help-desk__content .help-desk__label {
    font-weight: normal;
    text-transform: uppercase;
    color: #aaa;
}
.help-desk .help-desk__content .help-desk__input {
    width: 100%;
    padding: 14px 60px 14px 14px;
    border: none;
    outline: none;
    border-radius: 3px;
    background: #fff;
    font: inherit;
    font-size: 16px;
    line-height: 1.4;
    transition: box-shadow 0.25s cubic-bezier(0.55, 0, 0.1, 1);
}
.help-desk .help-desk__content .help-desk__input:focus {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
}
.help-desk .help-desk__widgets {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 25px 25px 10px;
}
.help-desk .help-desk__widgets .help-desk__widget {
    width: 33.33%;
    border: none;
    outline: none;
    background: none;
}
.help-desk .help-desk__widgets .help-desk__widget:hover .box,
.help-desk .help-desk__widgets .help-desk__widget:focus .box {
    transform: translateY(-4px);
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    background: #318fa9;
    color: #fff;
}
.help-desk .help-desk__widgets .help-desk__widget .box {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 3px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
    color: #333;
    transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
}
.help-desk .help-desk__widgets .help-desk__widget > p {
    margin-top: 10px;
    font-size: 12px;
    color: #595c5f;
}
.help-desk.help-desk--expanded .help-desk__menu {
    transform: translateX(-100vw) translateY(-50%);
    box-shadow: -6px 0 15px 0 rgba(0, 0, 0, 0.15);
}
.help-desk.help-desk--expanded .help-desk__content {
    transform: translateX(-100vw);
}
@media (min-width: 450px) {
    .help-desk.help-desk--expanded .help-desk__menu {
        transform: translateX(-450px) translateY(-50%);
        box-shadow: -6px 0 15px 0 rgba(0, 0, 0, 0.15);
    }
    .help-desk.help-desk--expanded .help-desk__content {
        transform: translateX(-450px);
        box-shadow: -3px 0 15px 0 rgba(0, 0, 0, 0.25);
    }
}

.help-desk__back,
.help-desk__close {
    display: flex;
    align-items: center;
    opacity: 0.6;
    position: absolute;
    top: 0;
    height: 48px;
    padding: 0 25px;
    border: none;
    outline: none;
    background: none;
    font-size: 32px;
    transition: opacity 0.2s;
}
.help-desk__back svg,
.help-desk__close svg {
    position: relative;
    top: -2px;
    width: 14px;
    height: 14px;
    fill: #fff;
}
.help-desk__back:hover,
.help-desk__back:focus,
.help-desk__close:hover,
.help-desk__close:focus {
    opacity: 1;
}

.help-desk__back {
    left: 0;
}

.help-desk__close {
    right: 0;
}
.help-desk__faq {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.help-desk__search,
.help-desk__faq-content {
    padding: 25px;
}

.help-desk__search label {
    display: none;
}

.help-desk__faq-content {
    flex: 1;
    overflow: hidden;
    background: #fff;
}
.help-desk__faq-content.scrollable {
    overflow: auto;
}

.help-desk__category,
.help-desk__category-title,
.help-desk__article-title {
    display: block;
    margin-bottom: 20px;
    font-size: 16px;
    color: inherit;
}
.help-desk__category svg,
.help-desk__category-title svg,
.help-desk__article-title svg {
    width: 10px;
    height: 10px;
    margin-right: 8px;
    fill: #333;
}

.help-desk__category-title {
    color: inherit;
    cursor: pointer;
}
.help-desk__category-title:hover,
.help-desk__category-title:focus {
    text-decoration: none;
    color: #318fa9;
}

.help-desk__article-title {
    text-decoration: underline;
    cursor: pointer;
}

.help-desk__category > .help-desk__faq-items {
    display: none;
    margin-left: 30px;
}

.help-desk__category.expanded > .help-desk__category-title {
    font-weight: bold;
}

.help-desk__category.expanded > .help-desk__faq-items {
    display: block;
}

.help-desk__article {
    position: absolute;
    overflow: auto;
    top: 48px;
    left: 25px;
    right: 0;
    bottom: 0;
    padding: 25px;
    box-shadow: -4px 0 4px rgba(0, 0, 0, 0.05);
    background: #f9f9f9;
    font-size: 16px;
    line-height: 1.6;
    animation: 0.2s ease-in-out helpDeskSlideIn;
    animation-fill-mode: forwards;
}
.help-desk__article.exiting {
    animation: 0.2s ease-in-out helpDeskSlideOut;
}
.help-desk__article h4 {
    margin: 0 0 20px;
    padding-right: 35px;
    font-size: 26px;
}
.help-desk__article img,
.help-desk__article iframe {
    width: 100%;
}

.help-desk__thumb-ratings {
    position: relative;
    margin: 40px 0 25px;
    text-align: center;
}
.help-desk__thumb-ratings.selected .help-desk__thumb-rating:not(.selected) {
    opacity: 0.4;
    pointer-events: none;
}
.help-desk__thumb-ratings .help-desk__thanks {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    color: #999;
}

.help-desk__thumb-rating {
    width: 48px;
    height: 48px;
    margin-right: 18px;
    padding: 10px;
    border: 3px solid #aaa;
    border-radius: 50%;
    background: none;
    color: #aaa;
    transition: all 0.2s ease-in-out;
}
.help-desk__thumb-rating:last-child {
    margin-right: 0;
}
.help-desk__thumb-rating.help-desk__thumb-rating--up {
    border-color: #39c5a5;
    color: #39c5a5;
}
.help-desk__thumb-rating.help-desk__thumb-rating--up:hover,
.help-desk__thumb-rating.help-desk__thumb-rating--up:focus,
.help-desk__thumb-rating.help-desk__thumb-rating--up.selected {
    background: #39c5a5;
    color: #fff;
}
.help-desk__thumb-rating.help-desk__thumb-rating--up svg {
    position: relative;
    top: -2px;
}
.help-desk__thumb-rating.help-desk__thumb-rating--down {
    border-color: #ff7c7c;
    color: #ff7c7c;
}
.help-desk__thumb-rating.help-desk__thumb-rating--down:hover,
.help-desk__thumb-rating.help-desk__thumb-rating--down:focus,
.help-desk__thumb-rating.help-desk__thumb-rating--down.selected {
    background: #ff7c7c;
    color: #fff;
}
.help-desk__thumb-rating svg {
    width: 24px;
    height: 24px;
}

.help-desk__empty-state {
    padding: 30px 0;
    text-align: center;
}

@keyframes helpDeskSlideIn {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes helpDeskSlideOut {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}
.help-desk__tutorials {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    overflow: auto;
    max-height: 100%;
    padding: 25px;
}

.help-desk__tutorials-help {
    margin: 0 25px 25px;
    font-size: 14px;
    text-align: center;
    color: #aaa;
}

.help-desk__tutorial {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-basis: 47%;
    min-height: 150px;
    margin-bottom: 25px;
    padding: 10px;
    border: none;
    outline: none;
    background: #fff;
    font-size: 16px;
    text-align: center;
}
.help-desk__tutorial:hover .help-desk__tutorial-play,
.help-desk__tutorial:focus .help-desk__tutorial-play {
    opacity: 1;
}
.help-desk__tutorial img {
    width: 100%;
}
.help-desk__tutorial p {
    flex: 1;
    display: flex;
    align-items: center;
    margin: 0;
}

.help-desk__tutorial-thumbnail {
    position: relative;
    margin-bottom: 8px;
}

.help-desk__tutorial-play {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    padding: 8px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.2);
    transition: opacity 0.15s ease-in-out;
    fill: #fff;
    stroke: transparent;
}
.help-desk__tutorial-play polygon {
    transform: translateX(8%);
}

.help-desk__back_2 svg,
.help-desk__close_2 svg {
    position: relative;
    top: -2px;
    width: 14px;
    height: 14px;
    fill: #fff;
}

.help-desk__back2,
.help-desk__close2 {
    display: flex;
    align-items: center;
    opacity: 0.6;
    position: absolute;
    top: 0;
    height: 48px;
    padding: 0 25px;
    border: none;
    outline: none;
    background: none;
    font-size: 32px;
    transition: opacity 0.2s;
}
